import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, switchMap } from 'rxjs';

import { ActivityService } from 'app/features/activities-management/services/activity.service';
import { EmployeeActivityService } from 'app/features/activities-management/services/employee-activity.service';
import { ActivitiesActions } from 'app/features/activities-management/state/activities.actions';
import { PreferredActivityService } from 'app/features/employee-management/shared/preferred-activity.service';

@Injectable()
export class ActivitiesEffects {
    constructor(
        private actions$: Actions,
        private activityService: ActivityService,
        private employeeActivityService: EmployeeActivityService,
        private preferredActivityService: PreferredActivityService,
    ) {}

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadActivities),
            concatMap(() =>
                this.activityService.getAll().pipe(
                    map((activities) => ActivitiesActions.loadActivitiesSuccess({ activities })),
                    catchError((error) => of(ActivitiesActions.loadActivitiesFailure({ message: error }))),
                ),
            ),
        ),
    );

    loadActivitiesByEmployeeId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.getActivityById),
            mergeMap(({ activityId }) =>
                this.activityService.getById(activityId).pipe(
                    map((activity) => ActivitiesActions.loadActivitiesByIdSuccess({ activity })),
                    catchError((error) => of(ActivitiesActions.loadActivitiesFailure({ message: error }))),
                ),
            ),
        ),
    );

    removeEmployeeFromActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.removeEmployeeActivityById),
            mergeMap(({ activityId, employeeId }) =>
                this.preferredActivityService.removeEmployeePreferredActivity(employeeId, activityId).pipe(
                    map((employee) =>
                        ActivitiesActions.removeEmployeeActivityByIdSuccess({
                            employeeId: employee.employeeId,
                        }),
                    ),
                    catchError((error) =>
                        of(ActivitiesActions.removeEmployeeActivityByIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    loadEmployeesByActivityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadEmployees),
            mergeMap(({ activityId }) =>
                this.employeeActivityService.getEmployeesByActivityId(activityId).pipe(
                    map((employees) => ActivitiesActions.loadEmployeesByActivityIdSuccess({ employees })),
                    catchError((error) =>
                        of(ActivitiesActions.loadEmployeesByActivityIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    updateActivityRolesById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateActivityRolesById),
            mergeMap(({ activityId, roles }) =>
                this.activityService.updateActivityRolesById(activityId, roles).pipe(
                    map((activity) => ActivitiesActions.updateCropSegmentActivityByIdSuccess({ activity })),
                    catchError((error) =>
                        of(ActivitiesActions.updateCropSegmentActivityByIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    updateActivitySegmentByActivityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateCropSegmentActivityById),
            mergeMap(({ activityId, segmentId }) =>
                this.activityService.updateActivitySegmentByActivityId(activityId, segmentId).pipe(
                    // Since the update API returns 204, directly call getById to fetch updated activity
                    switchMap(() =>
                        this.activityService.getById(activityId).pipe(
                            map((activity) => {
                                return ActivitiesActions.updateCropSegmentActivityByIdSuccess({ activity });
                            }),
                            catchError((error) =>
                                of(ActivitiesActions.loadActivitiesFailure({ message: error })),
                            ),
                        ),
                    ),
                    catchError((error) =>
                        of(ActivitiesActions.updateCropSegmentActivityByIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );
}
