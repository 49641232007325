import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';
import { EmployeeInfo } from 'app/features/employee-management/models/employeeInfo.model';
import { BaseService } from 'app/features/employee-management/shared/base.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeActivityService extends BaseService {
    constructor(httpClient: HttpClient, appConfigurationService: AppConfigurationService) {
        super(httpClient);
        this.setBaseUrl(`${appConfigurationService.configuration.uris?.employeeService}`);
    }

    public getEmployeesByActivityId(activityId: string) {
        const url = `${this.getBaseUrl()}/activity/${activityId}`;
        return this.getRequest<EmployeeInfo[]>(url);
    }
}
