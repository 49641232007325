import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ActivityList, UpdateActivitiesList } from '../models/activitiesList.model';

export const PreferredActivitiesActions = createActionGroup({
    source: 'PreferredActivities',
    events: {
        'Load PreferredActivities': emptyProps(),
        'Load PreferredActivities Success': props<{ activities: ActivityList }>(),
        'Load PreferredActivities Failure': props<{ message: any }>(),

        'Get Employee PreferredActivities By Id': props<{ id: string }>(),
        'Load PreferredActivities By EmployeeId Success': props<{ activities: ActivityList }>(),

        'Update PreferredActivities': props<{ activities: UpdateActivitiesList }>(),
        'Update PreferredActivities Success': props<{ activities: ActivityList }>(),
        'Update PreferredActivities Failure': props<{ message: any }>(),
    },
});
