import { createReducer, on } from '@ngrx/store';

import { Activity } from 'app/core/activities/models/activity.model';
import { ActivitiesActions } from 'app/features/activities-management/state/activities.actions';
import { EmployeeInfo } from 'app/features/employee-management/models/employeeInfo.model';

export interface ActivitiesState {
    loading: boolean;
    activities: Activity[];
    activity: Activity | null;
    errorMessage: string;
    employees: EmployeeInfo[] | [];
    errorMessageCropSegment?: string | null;
}

const initalState: ActivitiesState = {
    loading: true,
    activities: [],
    employees: [],
    activity: null,
    errorMessage: '',
    errorMessageCropSegment: '',
};

export const activitiesReducer = createReducer(
    initalState,
    on(ActivitiesActions.loadActivities, (state) => ({
        ...state,
        loading: true,
        activities: [],
        employees: [],
        errorMessage: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadEmployees, (state) => ({
        ...state,
        loading: true,
        employees: [],
        errorMessage: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadActivitiesSuccess, (state, { activities }) => ({
        ...state,
        loading: false,
        activities: activities,
        errorMessage: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadActivitiesByIdSuccess, (state, { activity }) => ({
        ...state,
        loading: false,
        activities: state.activities.map((a: Activity) =>
            a.id === activity.id
                ? {
                      ...a,
                      cropSegment: activity.cropSegment,
                  }
                : a,
        ), // Update the CropSegment by activityId
        activity: activity,
        errorMessage: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.updateCropSegmentActivityByIdSuccess, (state, { activity }) => ({
        ...state,
        loading: false,
        activities: state.activities.map((a: Activity) =>
            a.id === activity.id
                ? {
                      ...a,
                      cropSegment: activity.cropSegment,
                  }
                : a,
        ), // Update the CropSegment by activityId
        activity: activity,
        errorMessage: '',
        errorMessageCropSegment: activity.cropSegment === null ? '' : null,
    })),
    on(ActivitiesActions.loadActivitiesFailure, (state, { message }) => ({
        ...state,
        activities: [],
        errorMessage: message,
        loading: false,
    })),
    on(ActivitiesActions.updateCropSegmentActivityByIdFailure, (state, { message }) => ({
        ...state,
        errorMessageCropSegment: message,
        loading: false,
    })),
    on(ActivitiesActions.resetCropSegmentActivityByIdMessage, (state, { message }) => ({
        ...state,
        errorMessageCropSegment: message,
        loading: false,
    })),

    on(ActivitiesActions.loadEmployeesByActivityIdSuccess, (state, { employees }) => ({
        ...state,
        employees: employees,
        errorMessage: '',
        errorMessageCropSegment: '',
        loading: false,
    })),
    on(ActivitiesActions.loadEmployeesByActivityIdFailure, (state, { message }) => ({
        ...state,
        employees: [],
        errorMessage: message,
        loading: false,
    })),

    on(ActivitiesActions.removeEmployeeActivityById, (state, { employeeId, activityId }) => ({
        ...state,
        employees: state.employees.filter((e: EmployeeInfo) => e.id !== employeeId),
        errorMessage: '',
        errorMessageCropSegment: '',
        loading: false,
    })),
);
